import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {CheckoutSettingsStoreProps} from '../../types/app.types';
import {CheckoutService} from '../services/CheckoutService';
import {CheckoutSettingsService} from '../services/CheckoutSettingsService';
import {CountryCode} from '@wix/form-fields';

export class CheckoutSettingsStore {
  private readonly siteStore: SiteStore;
  private readonly checkoutSettingsService: CheckoutSettingsService;
  private readonly checkoutService: CheckoutService;

  constructor({
    siteStore,
    checkoutSettingsService,
    checkoutService,
  }: {
    siteStore: SiteStore;
    checkoutSettingsService: CheckoutSettingsService;
    checkoutService: CheckoutService;
  }) {
    this.siteStore = siteStore;
    this.checkoutSettingsService = checkoutSettingsService;
    this.checkoutService = checkoutService;
  }

  public addPreselectedFlowCountryIfNeeded(country: CountryCode) {
    this.checkoutSettingsService.addEnabledDeliveryCountry(country);
  }

  public toProps(): CheckoutSettingsStoreProps {
    return {
      checkoutSettings: this.checkoutSettingsService.checkoutSettings,
      showCouponSP: this.checkoutSettingsService.showCouponSP,
      doesFastFlowFormHaveFieldsToDisplay: this.checkoutSettingsService.doesFastFlowFormHaveFieldsToDisplay,
      showExpressCheckoutSP: this.checkoutSettingsService.showExpressCheckoutSP,
    };
  }
}
